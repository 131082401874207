/**

We need this file to include the fonts because angular only recognizes the font file, when we import them in the architecture in our angular.json

 */
@font-face {
    font-family: 'BentonSans';
    src:
        url('../../assets/font/BentonSans/BentonSansRegular.eot') format('eot'),
        url('../../assets/font/BentonSans/BentonSansRegular.woff') format('woff'),
        url('../../assets/font/BentonSans/BentonSansRegular.otf') format('opentype'),
        url('../../assets/font/BentonSans/BentonSansRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BentonSans';
    src:
        url('../../assets/font/BentonSans/BentonSansBold.eot') format('eot'),
        url('../../assets/font/BentonSans/BentonSansBold.woff') format('woff'),
        url('../../assets/font/BentonSans/BentonSansBold.otf') format('opentype'),
        url('../../assets/font/BentonSans/BentonSansBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Atkinson Hyperlegible';
    src:
        url('../../assets/font/atkinson-hyperlegible/Web Fonts/EOT/Atkinson-Hyperlegible-Regular-102.eot') format('eot'),
        url('../../assets/font/atkinson-hyperlegible/Web Fonts/WOFF/Atkinson-Hyperlegible-Regular-102.woff')
            format('woff'),
        url('../../assets/font/atkinson-hyperlegible/Web Fonts/TTF/Atkinson-Hyperlegible-Regular-102.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Atkinson Hyperlegible';
    src:
        url('../../assets/font/atkinson-hyperlegible/Web Fonts/EOT/Atkinson-Hyperlegible-Bold-102.eot') format('eot'),
        url('../../assets/font/atkinson-hyperlegible/Web Fonts/WOFF/Atkinson-Hyperlegible-Bold-102.woff') format('woff'),
        url('../../assets/font/atkinson-hyperlegible/Web Fonts/TTF/Atkinson-Hyperlegible-Bold-102.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Franklin Gothic Book';
    src:
        url('../../assets/font/franklin-gothic/FranklinGothicBookRegular.eot') format('eot'),
        url('../../assets/font/franklin-gothic/FranklinGothicBookRegular.woff') format('woff'),
        url('../../assets/font/franklin-gothic/FranklinGothicBookRegular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Franklin Gothic Book';
    src:
        url('../../assets/font/franklin-gothic/FranklinGothicBookRegular.eot') format('eot'),
        url('../../assets/font/franklin-gothic/FranklinGothicBookRegular.woff') format('woff'),
        url('../../assets/font/franklin-gothic/FranklinGothicBookRegular.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Franklin Gothic Medium';
    src:
        url('../../assets/font/franklin-gothic/FranklinGothicMediumRegular.eot') format('eot'),
        url('../../assets/font/franklin-gothic/FranklinGothicMediumRegular.woff') format('woff'),
        url('../../assets/font/franklin-gothic/FranklinGothicMediumRegular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Franklin Gothic Heavy';
    src:
        url('../../assets/font/franklin-gothic/FranklinGothicHeavyRegular.eot') format('eot'),
        url('../../assets/font/franklin-gothic/FranklinGothicHeavyRegular.woff') format('woff'),
        url('../../assets/font/franklin-gothic/FranklinGothicHeavyRegular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
